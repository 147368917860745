<!-- 关于我们 -->
<template>
  <div>
    <div class="wd_huw">
      <div class="f_huefg">
        <img src="../../../image/cheshijijiesao.png" alt="" />
      </div>
      <div class="wef_huwf">
        <div class="egn_huef">车世际专注于</div>
        <div class="egn_huef">汽车后服务市场 回归共享本质</div>
        <div class="erg_hugwwsf">
          “车世际”隶属于四川车世际科技有限公司，致力于在出行市场回归共享本质，降低乘客司机、平台各方运营成本，同时提供二手车交易、租赁、平价保养的用车服务平台，平台推出“你不开车我买单、闲置车辆来赚钱”的生态模型，倡导用车养家的理念，从共享本质出发，
          充分利用闲置资源为广大中低收入车主能够实现“用车少花钱，养车花小钱、不开车可赚钱”服务。
        </div>
      </div>
      <div class="eg_hgywf">
        <div class="eg_hgw">
          <video
            ref="vueMiniPlayer"
            @ended="onEnded"
            controls="controls"
            class="e_hugwef"
            src="../../../image/f054c3c588781d1db4b20309f3ed8544.mp4"
          ></video>
          <img
            v-if="isShowVideoImg"
            class="eg_huwef"
            @click="onVideo"
            src="../../../image/video_img.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="h_hfft">
      <img src="../../../image/guanyuwomen.png" alt="" />
      <div class="wef_hyt">
        <img src="../../../image/tuandui.png" alt="" />
      </div>
      <div class="ikqkooq">
        <div class="eg-hgttrrqd">
          <div class="eg_huhwfg">2021</div>
          <div class="uhuwef">
            <p class="eg_uwhufwf">成立时间</p>
            <p class="eg_uwhufwf">成立于2021年</p>
          </div>
        </div>
        <div class="eg-hgttrrqd">
          <div class="eg_huhwfg">18+</div>
          <div class="uhuwef">
            <p class="eg_uwhufwf">覆盖四川</p>
            <p class="eg_uwhufwf">四川市辖区18+</p>
          </div>
        </div>
        <div class="eg-hgttrrqd">
          <div class="eg_huhwfg">600+</div>
          <div class="uhuwef">
            <p class="eg_uwhufwf">合作伙伴</p>
            <p class="eg_uwhufwf">四川合作伙伴600+</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      isShowVideoImg: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onVideo() {
      this.isShowVideoImg = false;
      this.$refs.vueMiniPlayer.play(); //播放
    },
    onEnded() {
      this.isShowVideoImg = true;
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.wd_huw {
  position: relative;
}
.f_huefg {
  padding: 120px 0 0 240px;
}
.wef_huwf {
  width: 836px;
  height: 472px;
  background: #ffffff;
  border: 1px solid #707070;
  margin-left: 240px;
  margin-top: 60px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.egn_huef {
  font-size: 48px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 78px;
  color: #272727;
}
.erg_hugwwsf {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 32px;
  color: #272727;
  padding-top: 80px;
  width: 600px;
}
.e_hugwef {
  width: 783px;
  height: 468px;
}
.eg_hgywf {
  width: 783px;
  height: 468px;
  position: absolute;
  top: 120px;
  right: 100px;
}
.eg_hgw {
  position: relative;
  width: 783px;
  height: 468px;
}
.eg_huwef {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.h_hfft {
  width: 100%;
  position: relative;
  margin-top: 120px;
  height: 1126px;
}
.wef_hyt {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 75px;
}
.ikqkooq {
  display: flex;
  width: 60%;
  position: absolute;
  top: 125px;
  right: 0;
  justify-content: space-around;
}
.eg-hgttrrqd {
  display: flex;
  flex-direction: column;
}
.eg_huhwfg {
  font-size: 65px;
  font-family: DIN;
  font-weight: bold;
  line-height: 64px;
  color: #ffffff;
}
.uhuwef {
  padding-top: 15px;
}
.eg_uwhufwf {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}
</style>